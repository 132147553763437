#container{
    position: relative;
    box-shadow: 0 0  6px black;
     border-radius: 4em;
     border: 6px solid transparent;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
   backdrop-filter: blur(15px);
  margin: 3% auto;
    width: 400px;
    overflow: hidden;
  z-index: 1;
  background-color: rgb(206, 198, 198);
}
.o{     
    content: '';
    position: absolute;
    width: 100%;
    height: 100vh;
    background:rgba(0, 0, 0, 0.6) ;
    margin: 0;
    top:0;
    display: flex;
    justify-content: center;
    align-items: center;
  
}
h3{
    margin-left:30%;
    
    font-weight: bold; 
}
.h61{
   
    font-weight: bold; 
}
.otpname{
  margin-top: 5%;

}
.otpinput{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10% 5% 20% 20%;
    gap: 3%;
}
#d1,#d2,#d3,#d4,#d5,#d6{
    background-color: #e9b17d;
    width: 30px;
    height: 40px;
    font-size: 2em;
    color:black;
    border: 4px solid transparent;
 
    border-radius:30em;
    outline: none;
    padding-left: 5%;
  
   box-shadow: none;
  
 
    
}
#d1:hover{
  outline: 3px solid black;
}
#d2:hover{
  outline: 3px solid black;
}
#d3:hover{
  outline: 3px solid black;
}
#d4:hover{
  outline: 3px solid black;
}
#d5:hover{
  outline: 3px solid black;
}

#d6:hover{
  outline: 3px solid black;
}

.verifybtn{
    
        font-weight: bold;
       
     border: 4px solid transparent;
     
      border-radius: 30em;
      padding: 2px 5px 2px 5px;
      color:black;
      cursor: pointer;
      margin-left: 8%;
      margin-bottom: 20px;
      width: 100px;
      background-color:  #ff8b1f;;
      
    
}
.verifybtn:hover{
  box-shadow: 0 0 10px #0e0e0e;
  transform: scale(1.08); 
}


    

