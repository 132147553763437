.bookslotcontainer {
  position: absolute;
  top: 57px;

  background-color: rgb(210, 205, 205);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  z-index: 10;
}

.book {
  position: absolute;
  top: 15%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}
.b2 {
  width: 100px;
  height: 100px;

  display: flex;
  justify-content: center;

  align-items: center;
  cursor: pointer;
  border-radius: 1em;
  border: 3px solid black;
}
.bookslotbutton1 {
  position: absolute;
  top: 50%;
  right: 43%;
  font-weight: bold;
  font-size: 17px;
  border: none;
  width: 12%;
  height: 8%;
  border-radius: 1em;
  padding: 2px 5px 2px 5px;
  color: white;
  cursor: pointer;
  transition: 1.5ms;
  background-color: #ff8b1f;
  transition: 0.3s ease;
  outline: none;
}
.parkingtitle {
  color: black;
  background-color: #ff8b1f;

  left: 40%;
  height: 50px;
  padding: 2px 15px 0px 15px;
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
}

.bookslotbutton1:hover {
  transform: scale(1.08);
}
.b2:hover {
  transform: scale(1.08);
}
