nav a.active {
  text-decoration: none;
  font-weight: bold;
  /*background-image:repeating-conic-gradient(rgb(179, 99, 99) 30% 40%,  white 40% 50%);
    background: -webkit-linear-gradient(right,#00dbde,#fc00ff,#00dbde,#fc00ff);*/
  padding: 4px 8px 4px 8px;

  color: rgb(241, 241, 230);
  background-color: #ff8b1f;
  box-shadow: 0 0 10px #0e0e0e;

  /* background: linear-gradient(black) padding-box,
    linear-gradient(to right,#00dbde,#ff8c00,#00dbde,#ff00fb) border-box;*/
  border-radius: 30em;
  border: 4px solid transparent;
}
nav {
  position: relative;
  padding: 16px 32px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  margin-right: 16%;
  gap: 2%;
}

nav a {
  margin-right: 16px;

  text-decoration: none;
  font-weight: bold;
  font-size: 18px;
  color: white;
}
nav :hover {
  transform: scale(1.08);
}
.logo {
  position: absolute;
  width: 600px;
  height: 50vh;

  margin-top: 3%;
  margin-left: 5%;
  background-image: url("../assets/logo1.png");
  background-size: 50%;
  background-repeat: no-repeat;
}
