/* .loading{
   
    width:100px;
    height: 100px;
    border-radius: 50%;
    border: 4px solid white;
    border-top-color: black;
    animation: rotate 1s infinite;
} */
.loadbackgorund {
  content: "";
  position: absolute;

  width: 100%;
  height: 108vh;
  backdrop-filter: blur(15px);
  margin: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
}

/* @keyframes rotate {
    100%{
        rotate: 360deg;
    }
    
} */

.loading {
  width: 80px;
  height: 80px;

  aspect-ratio: 1;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #ffa516 94%, #0000) top/8px 8px
      no-repeat,
    conic-gradient(#0000 30%, #ffa516);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: l13 1s infinite linear;
}
@keyframes l13 {
  100% {
    transform: rotate(1turn);
  }
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 80px;
  aspect-ratio: 1;
  display: grid;
  -webkit-mask: conic-gradient(from 15deg, #0000, #000);
  animation: l26 1s infinite steps(12);
}
.loader,
.loader:before,
.loader:after {
  background: radial-gradient(closest-side at 50% 12.5%, #ffa516 96%, #0000) 50%
      0/20% 80% repeat-y,
    radial-gradient(closest-side at 12.5% 50%, #ffa516 96%, #0000) 0 50%/80% 20%
      repeat-x;
}
.loader:before,
.loader:after {
  content: "";
  grid-area: 1/1;
  transform: rotate(30deg);
}
.loader:after {
  transform: rotate(60deg);
}

@keyframes l26 {
  100% {
    transform: rotate(1turn);
  }
}
