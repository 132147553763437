* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: Arial, Helvetica, "Times New Roman";
  overflow-x: hidden;
  overflow-y: visible !important;
}

.App {
  background: url("./assets/colorfullbackgroundimage.jpg") no-repeat center
    center/cover;

  width: 100%;
  height: 100vh;
  flex-direction: column;
}
@media screen and (max-width: 600px) {
  .App {
    width: 220%;
    height: 220vh;
  }
  .mainpage {
    width: 220%;
    height: 220vh;
  }
}
