.bookmark{
    position: absolute;
    z-index: 10;
    top:57px;
    width: 100%; 
   
    background-color:#f7f7f7 ;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
   
}
.mybookingstitle{
  color: black;
  background-color:  #ff8b1f;;
 
 
  height: 50px;
  padding: 2px 15px 0px 15px;
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
}
.mybookingsdetails {
  display: grid;
  grid-template-columns: 1fr 1fr; 
  grid-template-rows: 1fr 1fr; 
  gap: 10px; 
}
.b1{
   
    width: 600px;
    height: 100px;
    gap: 10px;
    margin-top:10px;
    background-color: rgba(246, 155, 18, 0.5);
     display: flex;
     justify-content: center;
     flex-direction: column;
     align-items: center;
     flex-direction: row;
  
     border-radius: 2em;
     border: 2px solid black ;
   
    

}