.container {
  position: relative;
  box-shadow: 0 0 6px black;

  border-radius: 1em 4em 1em 4em;
  border: 3px solid orange;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  backdrop-filter: blur(18px);
  margin: 3% auto;
  width: 400px;

  z-index: 1;
}
#toast {
  position: absolute;
  top: 50px;
  left: 40%;
  border: 2px solid orange;
  border-radius: 2em;
  height: 50px;
  width: 300px;
  text-align: center;
  background-color: chartreuse;
  display: none;
}
/*.signf{
  border: 2px solid white;
border-top-color: #0e0e0e;
animation:  ani 1s infinite;
border-radius: 50%;
width: 100px;
height: 100px;
}
@keyframes ani {
  100%{
    rotate: 360deg;
  }
}*/

.signname {
  border-radius: 30em;
  padding: 6px 10px;
  color: white;
}
/*.signf::before{
   content: '';
   position: absolute;
   width: 25%;
   height: 40px;
   margin-top: -5px;
   margin-left: -4px;
   animation: animate linear 8s infinite;
   border: 2px solid rgb(236, 233, 231);
   border-radius: 4em;
    z-index: 1;
}
@keyframes  animate{
    0%
    {
      transform: rotate(0deg);
    }
   
   100%
    {
      transform: rotate(360deg);
    }
}*/
.form-group {
  padding: 5px;
  position: relative;
  width: 310px;
}
label {
  position: absolute;
  margin-top: -5%;
  left: 5px;
  transform: translateY(-50%);
  font-size: 1em;
  color: white;
  font-weight: bold;
  cursor: pointer;
}
.icons {
  position: absolute;
  top: 25%;
  left: 4%;
  -moz-gap: 15px;
  height: 18px;
}
form {
  padding: 60px 40px 60px 0px;
  flex-direction: column;

  display: flex;
  justify-content: center;
  align-items: center;
}

/* This will override the default gap if Firefox doesn't fully support the standard `gap` property */
@-moz-document url-prefix() {
  form {
    gap: 15px; /* Adjusted gap value for Firefox */
  }
}

.inp {
  background: transparent;
  width: 100%;
  height: 40px;
  font-size: 1em;
  color: white;
  font-weight: bold;
  border: 1px solid orange;
  box-shadow: 0 0 10px #0e0e0e;
  border-radius: 30em;
  outline: none;
  padding-left: 12%;
}
inp::placeholder {
  color: white;
  opacity: 0.5;
}

.btn {
  margin-top: 5px;
  font-weight: bold;
  background-color: transparent;
  border: 4px solid transparent;
  box-shadow: 0 0 10px #0e0e0e;
  border-radius: 30em;
  padding: 4px 5px 4px 5px;
  color: white;
  cursor: pointer;
  outline: none;
  margin-left: 25px;
  background-color: #ff8b1f;
  transition: 0.3s;
}
.btn:hover {
  transform: scale(1.08);
}
